import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import axios from "axios";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Row, Form, Input } from 'antd';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import loading from '../../assets/img/loading-gif.gif';
import filesearch from '../../assets/img/filesearch.png';
import { useNavigate } from 'react-router-dom';

import Swal from 'sweetalert2';
const url = new URL(window.location.href);
const gameid = url.searchParams.get('id');
const url1 = new URL(window.location.href);
const name = url1.searchParams.get('name');
// alert(name);

// alert(gameid);
export default function Jodi() {
  const navigate = useNavigate();

  const data = Array.from({ length: 100 }, (_, index) => `Item ${index + 1}`);
  const [inputValues, setInputValues] = useState(Array(data.length).fill(''));
  const [attendeeEmails, setAttendeeEmails] = useState({});
  const [users, setUsers] = useState([]);
  const [areAnyInputsEmpty, setAreAnyInputsEmpty] = useState(false);
  const [betkey, setBetkey] = useState([]);
  const [betamount, setBetamount] = useState([]);
  const [session_name, setSessionname] = useState([]);
  const [setminibet, setMinibet] = useState([]);
  const [MaxbetAmt, setMaxbet] = useState([]);
  const [Maxpoints, setMaxpoints] = useState([]);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const [loading1, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const isButtonLoading = useRef(false);
  const [banned, setbanned] = useState([]);
  // console.warn(banned)

  const handleInputChange = (e, index) => {
    const newInputValues = [...inputValues];

    // newInputValues[index] = e.target.value;
    // setInputValues(newInputValues);
    // Extract only digits and limit to 4 characters
    const inputValue = e.target.value.replace(/\D/g, '').slice(0, 4);

    newInputValues[index] = inputValue;
    setInputValues(newInputValues);
    const newTotalPoints = newInputValues.reduce((acc, value) => acc + Number(value), 0);
    setTotalPoints(newTotalPoints);
    console.log(`${index}, ${e.target.value}`);

  };
  useEffect(() => {

    const emptyInputs = inputValues.every(value => value.trim() === '');
    setAreAnyInputsEmpty(emptyInputs);
  }, [inputValues]);
  const setBetkeyChange = (e) => {
    const betkeyy = e.target.value;
    setBetkey(betkeyy);
  };
  const setBetamountChange = (e) => {
    const amount = e.target.value;
    // console.warn(amount);
    setBetamount(amount);
  };
  const setSessionnameChange = (e) => {
    const Session = e.target.value;
    setSessionname(Session);
  };

  useEffect(() => {
    loaduser();
    app_manager();
    loaduser1();
  }, [])
  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    let url = (`${process.env.REACT_APP_API_URL}/POM_get_user_profile.php`);
    // console.warn(url);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data);

        const banned = response.data.banned;
        setbanned(banned)

        // alert(res)
        const objectRes = JSON.parse(res);
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const app_manager = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = (`${process.env.REACT_APP_API_URL}/POM_app_manager.php`);
    const formData = new FormData();
    formData.append('app_id', process.env.REACT_APP_API_ID);
    formData.append('user_id', user_id);
    // formData.append('market_id', gameid);
    formData.append('dev_id', dev_id);
    var config = {
      method: 'POST',
      url: url,
      body: formData,
    };
    axios.post(url, formData, config)
      .then(function (response) {
        var setpoints = response.data.points;
        setMaxpoints(setpoints)
        var min_redeem = response.data.data.min_redeem;
        // var mindeposit = response.data.data.min_deposit;
        // console.warn(min_redeem)
        // setMinredeem(min_redeem);
        // setDeposit(mindeposit);
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        // setAppmanager(objectRes);
        var setmininumbet = objectRes.jodi_min;
        console.warn(setmininumbet);
        setMinibet(setmininumbet)
        var setmax_betnumbet = objectRes.jodi_max;
        console.warn(setmax_betnumbet)
        setMaxbet(setmax_betnumbet)
        // setIsLoading(false);

        console.warn(objectRes);

      })

  }
  const loaduser = async () => {
    setLoading(true);
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    try {
      let url = (`${process.env.REACT_APP_API_URL}/POM_num_tbl.php`);
      // console.warn(url);
      const formData = new FormData();
      formData.append('app_id', process.env.REACT_APP_API_ID);
      formData.append('user_id', user_id);
      formData.append('market_id', gameid);
      formData.append('dev_id', dev_id);
      var config = {
        method: 'POST',
        url: url,
        body: formData,
      };
      axios.post(url, formData, config)
        .then(function (response) {
          var setmininumbet = response.data.mini_bet;
          var setmax_betnumbet = response.data.max_bet;
          var setpoints = response.data.points;
          // setMinibet(setmininumbet)
          // setMaxbet(setmax_betnumbet)
          setMaxpoints(setpoints)
          console.warn(setpoints)
          const res = JSON.stringify(response.data);
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      setLoading(false);
    }
  }

  const clearInputValues = () => {
    setInputValues(Array(data.length).fill(''));
  };

  // const handlePlayGame = () => {
  //   clearInputValues();
  //   playgame();
  // };
  const handlePlayGame = () => {
    if (banned == '0') {
      // clearInputValues();
      playgame();
    } else if (banned == '1') {
      setLoading2(true);
      setTimeout(() => {
        setLoading2(false);
      }, 20000);
    }
  };
  const playgame = async () => {

    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    console.warn(setminibet);
    console.warn(totalPoints);
    // if (setminibet > totalPoints) {
    //   // alert('5')
    //   // console.warn(setminibet);
    //   toast.error(`Maximum Bet Placed ${users.mini_bet}`);
    //   return;
    // }
    // if (MaxbetAmt < totalPoints) {
    //   // alert('1000')
    //   // console.warn(setminibet);
    //   toast.error(`Maximum Bet Placed ${users.max_bet}`);
    //   return;
    // }

    const isInvalidBet = inputValues.some(value => {
      const numericValue = parseFloat(value);
      const miniBet = parseFloat(users.mini_bet);
      const maxBet = parseFloat(users.max_bet);

      // Check if the value is below the minimum bet
      if (numericValue < miniBet) {
        toast.error(`Bet value ${numericValue} is below the minimum bet (${miniBet}). Please check your input.`);
        return true;
      }

      // Check if the value is above the maximum bet
      if (numericValue > maxBet) {
        toast.error(`Bet value ${numericValue} is above the maximum bet (${maxBet}). Please check your input.`);
        return true;
      }

      return false;
    });

    if (isInvalidBet) {
      return;
    }
    console.warn(users.points);
    console.warn(totalPoints);
    if (users.points < totalPoints) {
      const MySwal = withReactContent(Swal)
      MySwal.fire({
        title: 'You Dont Have Sufficient Balance',
        timer: 2000,
        icon: 'error'
      });
      return;
    }


    if (!isButtonLoading.current) {
      isButtonLoading.current = true;

      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
        isButtonLoading.current = false;
      }, 1000);
    }
    try {
      setIsButtonVisible(false);
      const betList = data.map((item, index) => {
        return {
          betkey: String(index).padStart(2, '0'), // replace betkey with the appropriate variable
          betamount: inputValues[index] || 0,
          // session_name: "open" 
          bettype: "8",
        };
      }).filter(item => item.betamount > 0);

      // setLoading2(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}play_game.php`, {

        user_id: user_id,
        dev_id: dev_id,
        market_id: gameid,
        app_id: process.env.REACT_APP_API_ID,
        // BetList: [{ betkey:betkey, betamount:betamount, session_name:"open" }],
        BetList: betList,
        dev_model: "web",
        devName: "sumsang"

      })

      if (response.data.success == 1) {
        // setTimeout(() => {
        clearInputValues();
        setLoading2(false);
        setShowSubmitButton(false);

        // }, 1000);
        // console.warn(response.data.success)
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: response.data.message,
          icon: 'success',
          timer: 2500
        })
          .then((result) => {
            navigate('/');
          })
      } else {
        const MySwal = withReactContent(Swal)
        MySwal.fire({
          title: response.data.message,
          timer: 2000,
          icon: 'error'
        });
        return;
      }

      // return;
    } catch (error) {
      console.error('Game store:', error);
      toast.error('An error occurred while Game store. Please try again later.');
    }
    // setLoading2(true);
  };


  return (
    <>

      <section className='jodi' id='jodi'>
        <div className="d-flex justify-content-between px-3 ">
          <div className="points">
            <h5>Points Remaining : {Math.max(0, users.points - totalPoints)}</h5>
            {/* <p>{Math.max(0, users.points - totalPoints)}</p> */}
          </div>
          {/* <div className="points">
            <h5>Points Added</h5>
            <p>{totalPoints}</p>
          </div> */}
        </div>
        <div className='d-flex flex-wrap justify-content-between'>
          {data.map((item, index) => (
            <>
              {index == '99' ?
                <div className='cardview' key="00">
                  <div className={`number ${inputValues[index - 99] ? 'green' : ''}`}>00</div>
                  <input
                    type='text'
                    inputMode='numeric'
                    className='form-input'
                    autoFocus={index - 99 === 0}
                    value={inputValues[index - 99]}
                    onChange={(e) => { handleInputChange(e, index - 99); }}
                    disabled={isButtonDisabled}
                  />
                </div>
                :
                <div className='cardview' key={index + 1}>
                  <div className={`number ${inputValues[index + 1] ? 'green' : ''}`}>{index + 1}</div>
                  <input
                    type='text'
                    inputMode='numeric'
                    // onKeyDown={blockInvalidChar}
                    className='form-input'
                    autoFocus={index + 1 === 0}
                    value={inputValues[index + 1]}
                    onChange={(e) => { handleInputChange(e, index + 1); }}
                    disabled={isButtonDisabled}
                  />
                </div>
              }
            </>
          ))}

          {/* <Button onClick={playgame} disabled={areAnyInputsEmpty} className='btn-add'>Play</Button> */}
          <div className="totalpoints">
            <div>
              <p className='mb-0'>₹<span className='ms-2'>{totalPoints}/-</span></p>
            </div>

            <Button className='btn-add' onClick={handlePlayGame} disabled={isButtonDisabled || loading2}>
              {loading2 ? <img src={loading} alt="Loading" /> : "Play Bet"}
            </Button>

          </div>

        </div>

      </section>


      {loading2 && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
      <ToastContainer />
    </>
  );
}
